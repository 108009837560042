import React from "react";

import SmallIcon from "../images/small_genki_logo.png";
import Genki from "../images/genki.png";
import AboutMe from "./AboutMe";

import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const HomePage = () => {
	const gradient = {
		backgroundImage: `linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(93,94,95,1) 0%, rgba(65,73,86,1) 58%)`,
		color: "white",
		fontSize: "16px",
		fontWeight: "bold",
	};
	const gradient2 = {
		backgroundImage: `linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(93,94,95,1) 0%, rgba(170,180,198,1) 50%)`,
		color: "black",
		fontSize: "16px",
		fontWeight: "bold",
	};

	return (
		<div className={"background1"}>
			<List>
				<ListItem disablePadding>
					<ListItemIcon>
						<img src={SmallIcon} style={{ height: "50px" }} />
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography
								type="body2"
								style={{
									color: "#FFFFFF",
									paddingTop: "1.5rem",
									fontSize: "20px",
								}}
							>
								WELCOME TO MY PORTFOLIO
							</Typography>
						}
					/>
				</ListItem>
			</List>
			<div style={{ paddingTop: "2rem" }}>
				<Grid container spacing={2}>
					<Grid item xs>
						<div style={{ textAlign: "center" }}>
							<h1 style={{ color: "white", letterSpacing: ".3em" }}>
								GENKI <br /> YOSHIDA
							</h1>
							<p style={{ color: "gray" }}>
								I am currently looking for a new role in a reputable <br />
								organization that will utilize my diverse skills in UI/UX <br />{" "}
								design and digital design.
							</p>
							<div style={{ textAlign: "center", padding: "1em" }}>
								<Button style={gradient}>ABOUT ME</Button>
								<span style={{ paddingLeft: "1em" }}></span>
								<Button style={gradient2}>SEE DESIGN WORKS</Button>
							</div>
						</div>
					</Grid>
					<Grid item xs>
						<div style={{ textAlign: "center" }}>
							<img
								src={Genki}
								alt="Remy Sharp"
								style={{
									height: "200px",
									width: "200px",
									border: "1px solid black",
									borderRadius: "50%",
								}}
							/>
						</div>
					</Grid>
				</Grid>
			</div>
			<div style={{ paddingBottom: "1.5em" }}>
				{/* <Grid container spacing={2}>
					<Grid item xs={4} md={4}>
						<img
							src="https://img.icons8.com/ios/50/ffffff/facebook-new.png"
							style={{ verticalAlign: "middle" }}
						/>
						<span style={{ color: "white" }}>facebook.com/genkiyoshi</span>
					</Grid>
					<Grid item xs={4} md={4}>
						<img
							src="https://img.icons8.com/ios/50/ffffff/apple-mail.png"
							style={{ verticalAlign: "middle" }}
						/>
						<span style={{ color: "white" }}>genkigenkiyoshida@gmail.com</span>
					</Grid>
					<Grid item xs={4} md={4}>
						<img
							src="https://img.icons8.com/ios/50/ffffff/instagram-new--v1.png"
							style={{ verticalAlign: "middle" }}
						/>
						<span style={{ color: "white" }}>instagram.com/genkiiii69/</span>
					</Grid>
				</Grid> */}
				<div style={{ textAlign: "center" }}>
					<div className={"footer1"}>
						<Grid
							container
							spacing={{ xs: 2, md: 3 }}
							columns={{ sm: 8, md: 12 }}
						>
							<Grid item xs={2} sm={4} md={4}>
								<img
									src="https://img.icons8.com/ios/50/ffffff/facebook-new.png"
									style={{ verticalAlign: "middle" }}
								/>
								<span style={{ color: "white" }}>facebook.com/genkiyoshi</span>
							</Grid>
							<Grid item xs={2} sm={4} md={4}>
								<img
									src="https://img.icons8.com/ios/50/ffffff/apple-mail.png"
									style={{ verticalAlign: "middle" }}
								/>
								<span style={{ color: "white" }}>
									genkigenkiyoshida@gmail.com
								</span>
							</Grid>
							<Grid item xs={2} sm={4} md={4}>
								<img
									src="https://img.icons8.com/ios/50/ffffff/instagram-new--v1.png"
									style={{ verticalAlign: "middle" }}
								/>
								<span style={{ color: "white" }}>
									instagram.com/genkiiii69/
								</span>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
			{/* <AboutMe /> */}
		</div>
	);
};

export default HomePage;
